<div
  class="ot-header"
  [ngClass]="{
    'expanded-header':
      (impersonateState | async) || (announcementMessage | async),
    'additional-header':
      (impersonateState | async) && (announcementMessage | async)
  }"
>
  <div class="impersonate-block" *ngIf="impersonateState | async">
    <div>Impersonation</div>
    <div class="impersonate-block__actions">
      <button class="btn btn-secondary" (click)="exitImpersonate()">
        Exit Impersonating
      </button>
    </div>
  </div>

  <div
    class="will-back-block"
    *ngIf="announcementMessage | async as announcementMessage"
  >
    {{ announcementMessage }}
  </div>

  <div
    class="container-fluid header-container"
    otSticky
    stickyClass="fixed-header"
    [stickyRecalc]="[impersonateState, announcementMessage]"
  >
    <div class="row">
      <div
        class="col-12 header-main justify-content-between justify-content-md-start"
      >
        <div class="title d-none d-md-block">{{ title }}</div>
        <div class="d-md-none">
          <div class="d-flex align-items-center">
            <button
              class="btn menu-btn"
              inlineSVG="/assets/svg/menu.svg"
              (click)="onOpenMobileMenuBar()"
            >
              Fultongrace
            </button>
            <img
              src="/assets/img/fgr-logo.svg"
              alt="fgr logo"
              class="fgr-logo"
            />
          </div>
        </div>
        <div
          class="mobile-friendly"
          [class.opened]="menuOpened"
          *ngIf="profile"
        >
          <div class="close-area">
            <a
              class="mobile-toggle"
              (click)="toggleMenu($event)"
              [inlineSVG]="'/assets/svg/close.svg'"
            ></a>
          </div>
          <ot-search></ot-search>
        </div>
        <ng-container *ngIf="config.flyout && profile?.roles">
          <ot-tasks-dropdown
            class="d-none d-md-block"
            *ngIf="profile?.roles?.is_ot_agent"
          ></ot-tasks-dropdown>
          <ot-work-queue-dropdown
            *ngIf="
              profile?.roles?.is_ot_teamlead || profile?.roles?.is_ot_admin;
              else noAdmin
            "
          >
          </ot-work-queue-dropdown>

          <ng-template #noAdmin>
            <div class="d-md-none mobile-search" (click)="onShowSearch()">
              <span
                inlineSVG="/assets/svg/global-search.svg"
                class="deal-search"
              ></span>
              <ot-search *ngIf="showSearchField">
                <span
                  additionalContent
                  inlineSVG="/assets/svg/close-table.svg"
                  (click)="onHideSearch($event)"
                  class="close-search"
                ></span>
              </ot-search>
            </div>
          </ng-template>
        </ng-container>

        <button
          class="d-md-none btn question-mark"
          inlineSVG="/assets/svg/question-mark-mobile.svg"
          (click)="openWidget()"
        ></button>

        <button
          class="btn d-none d-md-block question-mark"
          inlineSVG="/assets/svg/question-mark-desktop.svg"
          (click)="openWidget()"
        ></button>

        <ot-user-bar
          *ngIf="profile"
          (dropdownClosed)="closeMenu()"
          [profile]="profile"
        ></ot-user-bar>
      </div>
    </div>
    <ot-overlay-notifications></ot-overlay-notifications>
  </div>
</div>
