export class Patterns {
  public static PHONE =
    /^\(\d{3}\) \d{3}-\d{4}\s?(( |x|ext|ext.|extension|Ext|Ext.|Extension|#)?[ ]?([0-9]){1,7})?$/;
  public static POST_CODE = /^\d{5}(?:[-\s]\d{4})?$/;
  public static NUMBERS = /^\d+?$/;
  public static PASSWORD = /^(?=.*[0-9])(?=.*[A-Za-z])(.{8,})$/;
  public static SUBDOMAIN = /^[a-zA-Z0-9]+$/;
  public static CARD = /^\d{4} \d{4} \d{4} \d{4}$/;
  public static DATE = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/;
  public static NAME = /^[A-Za-z- ]+$/;
  public static SOCIAL_SECURITY_NUMBER = /^([X-]{6}|[0-9\-]{6})-([0-9]{4})$/;
  public static SOCIAL_SECURITY_NUMBER_FOR_REPLACE = /(\d{3})(\d{2})(\d{4})/;
}
