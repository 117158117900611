import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { Mask } from '@tenant/ot';
import { Field } from '../models/field.interface';
import { TypeaheadField } from '../models/typeahead-field';
import {
  DealClosingQuestionTemplate,
  Statuses,
} from 'apps/admin/src/app/deal/models/deal';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ot-dynamic-questions',
  templateUrl: './dynamic-questions.component.html',
  styleUrls: ['./dynamic-questions.component.scss'],
  providers: [],
})
export class DynamicQuestionsComponent implements Field, OnInit, OnDestroy {
  public get config(): TypeaheadField {
    return this._config;
  }
  public set config(value: TypeaheadField) {
    this._config = value;
  }
  public group: FormGroup;
  public rootGroup: FormGroup;
  public errors: any;
  public readonly: any;
  public dealClosingQuestionTemplates: DealClosingQuestionTemplate[] = [];
  public showBoost = false;
  public currencyMask: any = Mask.AMOUNT;
  public numberMask: any = Mask.NUMBER;

  private destroy = new Subject();
  private _config: TypeaheadField;

  constructor(private api: HttpClient, private cdr: ChangeDetectorRef) {}

  public ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }

  public ngOnInit(): void {
    this.group.get('deal_status').valueChanges.subscribe((status) => {
      if (status === Statuses.CLOSED) {
        this.requestData();
      } else {
        this.group.setControl('closing_questions', new FormGroup({}));
      }
    });
  }

  public requestData() {
    this.api
      .get<DealClosingQuestionTemplate[]>(this.config.meta.endpoint)
      .subscribe((res) => {
        let form = {};
        res.forEach((v) => {
          form[v.id] = new FormControl(
            null,
            v.required && v.question_type !== 'boost'
              ? [Validators.required]
              : []
          );
        });
        this.group.setControl('closing_questions', new FormGroup(form));
        this.dealClosingQuestionTemplates = res;
        const showBoostId = this.dealClosingQuestionTemplates.filter(
          (q) => q?.question_type === 'show_boost'
        )[0]?.id;
        this.group
          .get('closing_questions')
          .get(showBoostId?.toString())
          ?.valueChanges?.subscribe((status) => {
            const requiredBoost = this.dealClosingQuestionTemplates.filter(
              (q) => q.question_type === 'boost' && q.required
            );
            if (status.toLowerCase() === 'yes') {
              requiredBoost.forEach((q) => {
                this.group
                  .get('closing_questions')
                  .get(q.id.toString())
                  .setValidators([Validators.required]);
                this.group
                  .get('closing_questions')
                  .get(q.id.toString())
                  .updateValueAndValidity();
              });
            } else {
              requiredBoost.forEach((q) => {
                this.group
                  .get('closing_questions')
                  .get(q.id.toString())
                  .clearValidators();
                this.group
                  .get('closing_questions')
                  .get(q.id.toString())
                  .updateValueAndValidity();
              });
            }
          });
        this.cdr.detectChanges();
      });
  }

  public showBoostChange(answer: string) {
    if (answer?.toLocaleLowerCase() === 'yes') {
      this.showBoost = true;
    } else {
      this.showBoost = false;
    }
    this.cdr.detectChanges();
  }
}
