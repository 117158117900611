<div class="fix-ie-flex-min-height">
  <div class="root-wrapper" [class.d-none]="isMobileMenuBarOpen">
    <ot-menu-bar (showMenuBar)="openMenu($event)" class="d-none d-md-block" [class.show-menu]="menuIsOpen"></ot-menu-bar>
    <div class="main-page" [class.menu-open]="menuIsOpen">
      <ot-header (openMobileMenuBar)="isMobileMenuBarOpen = $event"></ot-header>
      <div class="project-pages">
        <router-outlet></router-outlet>
      </div>
      <div class="fix-ie-flex-footer"></div>
    </div>
  </div>
  <!-- <ot-footer></ot-footer> -->

  <div class="menu-bar--container d-none" [class.d-block]="isMobileMenuBarOpen">
    <div class="menu-bar--wrapper">
        <ot-menu-bar-wrapper [isMobileMenu]="true" (closeMobileMenu)="isMobileMenuBarOpen = $event"></ot-menu-bar-wrapper>
    </div>
  </div>
</div>
