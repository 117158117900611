import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { InlineSVGModule } from "ng-inline-svg";

import { ModalSucceedComponent } from "./components/modal-succeed/modal-succeed.component";

@NgModule({
    imports: [
        CommonModule,
        InlineSVGModule
    ],
    declarations: [
        ModalSucceedComponent
    ],
    exports: [
        ModalSucceedComponent
    ]
})

export class SharedModule { }