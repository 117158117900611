<label class="file-name">File Name</label>
<div class="task-document">
  <p class="task-document-name">
    <input type="text" class="form-control" *ngIf="document?.file" [formControl]="filename">
    <ng-container *ngIf="!document?.file">{{document?.file_name}}</ng-container>
  </p>
  <p class="task-document-date" *ngIf="document?.date_modified">{{document?.date_modified | date}}</p>
  <div class="task-document-icons">
    <a *ngIf="document?.file" [href]="" target="_blank" (click)="download($event)" class="icon-download ml-3"
      [inlineSVG]="'/assets/svg/download-file.svg'"></a>
    <a href="" *ngIf="document?.file" class="delete-icon ml-3" (click)="remove($event)"
      [inlineSVG]="'/assets/svg/close-table.svg'"></a>
  </div>
</div>

<div class="progress mt-2" *ngIf="!document?.file">
  <div class="indeterminate"></div>
</div>