import { Contact } from '../shared/deal-contact';

export interface BuyerName {
  id: number;
  value: string;
}

export interface SellerName {
  id: number;
  value: string;
}

export interface DealAddress {
  city: string;
  state: string;
  address_line_1: string;
  address_line_2: string;
}

export interface Commission {
  auto: boolean;
  paid: boolean;
  value: number;
}

export interface DealDetails {
  agent_role: number;
  buyer_name: BuyerName;
  list_price: number;
  sale_price: number;
  deal_status: number;
  lead_source: number;
  seller_name: SellerName;
  closing_date: string;
  deal_address: DealAddress;
  contract_date: string;
  total_deal_commission: Commission;
  company_gross_commission: Commission;
  coop_mls_agency_is_involved: boolean;

  [key: string]: any;
}

export interface AgentName {
  id: number;
  value: string;
}

export interface AgentCommission {
  type: string;
  value: number;
}

export interface AgentSection {
  id: number;
  paid: boolean;
  type: string;
  agent_name: AgentName;
  commission: number;
  agent_commission: AgentCommission;
  change_payment_status: boolean;

  [key: string]: any;
}

export interface RootObject {
  id: number;
  status: string;
  approval_state: string;
  type: string;
  status_events: string[];
  approval_status_events: string[];
  list_price: number;
  sale_price: number;
  deal_details: DealDetails;
  agent_section: AgentSection[];
  created_date: Date;

  [key: string]: any;
}

export interface DealAddress {
  city: string;
  state: string;
  address_line_1: string;
  address_line_2: string;
  zip_code?: string;
  country?: string;
}

export interface DealAgents {
  id?: number;
  agent_type?: string;
  agent_id?: number;
  commission: string;
  commission_type?: string;
  agent?: DealAgent;
  assigned_by?: DealAgent;
  commission_paid?: boolean;
  deduction_amount?: number;
  deduction_description?: string;
}

export interface DealAgent {
  id?: number;
  username?: string;
  first_name?: string;
  last_name?: string;
}

export interface DealAdditionalInfoCreate {
  receive_commission_method?: string;
  activate_follow_up_plan?: boolean;
  email_marketing?: boolean;
  boost_program?: boolean;
  boost_solicit_review?: boolean;
  boost_mailer_campaign?: boolean;
  boost_gift_type?: string;
  boost_gift_card_amount?: string;
  boost_recipient_names?: string;
  boost_type_of_card?: string;
  boost_gift_delivery_date?: string;
  boost_mailer_plus_campaign?: boolean;
  boost_mailer_plus_tenants?: string;
  boost_other_gift_type_description?: string;
}

export interface DealNotes {
  text: string;
}

export interface MlsAgency {
  id?: number;
  agent_name: string;
  brokerage_name: string;
  email: string;
  payment_due?: string;
  is_paid?: boolean;
}

export interface DealLandlord {
  id: number;
  name: string;
}

export interface DealLeadSource {
  id: number;
  name: string;
  deal_type: string;
}

export interface Deal {
  id?: number;
  deal_type: string;
  status: string;
  contract_date?: string;
  closing_date?: string;
  lease_execution_date?: string;
  lease_start_date?: string;
  lease_end_date?: string;
  agent_role: string;
  list_price?: string;
  sold_price?: string;
  landlord_name?: string;
  lead_source_id?: number;
  commission?: string;
  commission_type?: string;
  agents: DealAgents[];
  contacts: Contact[];
  address: DealAddress;
  additional_info?: DealAdditionalInfoCreate;
  notes?: DealNotes[];
  application_id?: number;
  company_gross_commission?: string;
  mls_agency?: MlsAgency;
  submission_status?: string;
  date_created?: string;
  landlord?: DealLandlord;
  lead_source?: DealLeadSource;
  rented_price?: string;
  flag?: string;
  rejection_reason?: string;
  rental_form?: number;
  rejected_by?: DealAgent;
  approved_by?: DealAgent;
  approved_at?: string;
  rejected_at?: string;
  invoice?: string;
  company_gross_commission_auto_calculated?: boolean;
  closing_questions?: DealClosingQuestionCreate[];
}

export enum DealTypes {
  SALES = 'S',
  RENTAL = 'R',
}

export enum Statuses {
  PENDING = 'P',
  CLOSED = 'CL',
  ACTIVE = 'AC',
  DEAD = 'D',
  COMING_SOON = 'CS',
  ARCHIVED = 'AR',
}

export enum SubmissionStatuses {
  NOT_SUBMITTED = 'NS',
  SUBMITTED = 'S',
  REJECTED = 'R',
  APPROVED = 'A',
}

export enum AgentRoles {
  LISTING_AGENT = 'LA',
  TENANT_AGENT = 'TA',
  BUYER_AGENT = 'BA',
  SELLER_AGENT = 'SA',
  REFERRAL_AGENT = 'RA',
  DUAL_AGENT = 'DA',
  DUAL_SALES_AGENT = 'DSA',
  DUAL_RENTAL_AGENT = 'DRA',
}

export enum AgentTypes {
  PRIMARY = 'P',
  ADDITIONAL = 'A',
}

export enum CommissionTypes {
  PERCENT = 'P',
  MONEY = 'M',
}

export interface DealFee {
  id?: number;
  name: string;
  value: string;
  value_type: CommissionTypes;
  editable?: boolean;
}

export interface DealGlobalFee {
  id?: number;
  name?: string;
  value: string;
  value_type: CommissionTypes;
}

export interface DealClosingAnswerOptionTemplate {
  id?: number;
  text: string;
}

export interface DealClosingQuestionTemplate {
  id?: number;
  question: string;
  question_type?: string;
  answer_type: string;
  required?: boolean;
  answer_options: DealClosingAnswerOptionTemplate[];
  answer?: string;
}

export interface DealClosingQuestionCreate {
  template: number;
  answer: string;
}

export interface DealLead {
  contacts: DealLeadContacts[];
  id: number;
  phone: string;
  user: DealLeadUser;
}

export interface DealLeadUser {
  email: string;
  id: number;
  name: string;
}

export interface DealLeadContacts {
  id: number;
  name: string;
}
