/* eslint-disable */
export class DealDocumentModel {
  public id: number;
  public file: File | string;
  public file_name: string;
  public name: string;
  public size: number;
  public user_id: number;
  public date_created: string;
  public date_modified: string;
  public task: any;

  constructor(json: any) {
    this.id = json.id;
    this.file = json.file;
    this.file_name = json.file_name;
    this.size = json.file_size;
    this.task = json.task;
    this.date_created = json.date_created;
    this.date_modified = json.date_modified;
  }

  public static fromArray(arr) {
    return arr.map((doc) => {
      return new DealDocumentModel(doc);
    });
  }
}
