<div class="task-item">
  <div class="task-item-side side-left">
    <ng-container *ngIf="task?.progress !== progress.EXEMPT">
      <ot-checkbox2 *ngIf="type !== 'document'" [disabled]="task?.disabled"
        [ngModel]="task.progress === progress.COMPLETE" (ngModelChange)="toggleComplete()" class="no-space">
      </ot-checkbox2>
      <ng-container *ngIf="type === 'document'">
        <div class="checkbox-area">
          <a [href]="task?.document?.file" target="_blank"
            *ngIf="task?.task_type === taskType.DOCUMENT && task?.document && task?.progress !== progress.EXEMPT"
            class="task-icon download-icon" [inlineSVG]="'/assets/svg/download.svg'"></a>
          <a (click)="edit($event)"
            *ngIf="task?.task_type === taskType.DOCUMENT && !task?.document && task?.progress !== progress.EXEMPT"
            class="task-icon upload-icon" [inlineSVG]="'/assets/svg/upload.svg'"></a>
        </div>
      </ng-container>
    </ng-container>
    <a *ngIf="task?.progress === progress.EXEMPT" class="incomplete-icon"
      [class.c-default]="!actions?.can_set_as_incomplete" (click)="reopen($event)"
      [inlineSVG]="'/assets/svg/incomplete.svg'">
    </a>
    <span [class.clickable]="task.progress === progress.ACTIVE && !task?.disabled" (click)="edit($event)">
      {{task?.summary}}
      <span class="required-mark" *ngIf="task?.required">*</span>
    </span>
    <img *ngIf="task?.due_date" class="task-icon" src='/assets/svg/date-picker.svg'>
    <span [class.overdue]="overdue()">{{task?.due_date | date: 'MMM dd, hh:mm a'}}</span>
  </div>
  <div class="task-item-side side-right overdue">
    <div class="task-exempted" *ngIf="task.progress === progress.EXEMPT">
      Exempt
      <img class="task-icon exempt-icon" *ngIf="task?.exempt_reason" [ngbTooltip]="task?.exempt_reason"
        triggers="click:click" placement="left" src='/assets/svg/help.svg' alt="">
    </div>
    <a href=""
      *ngIf="task?.progress === progress.ACTIVE && (!task?.disabled || deal.submission_status === submissionStatuses.APPROVED && user?.roles?.is_ot_teamlead)"
      class="delete-icon icon-close ml-3" ngbTooltip="Exempt" (click)="exempt($event)"
      [inlineSVG]="'/assets/svg/close.svg'"></a>
  </div>
</div>

<ng-template #notification let-data="data" let-processClose="processClose">
  <div class="col-12 notify task-notify" [class.warning]="data.type === 'warning'">
    <i class="fa fa-check" *ngIf="data.type === 'success'"></i>
    <i class="fa fa-exclamation-triangle" *ngIf="data.type === 'warning'" aria-hidden="true"></i>
    <span class="notify-text">{{ data.text }}</span> &nbsp;<a href="" *ngIf="!task?.disabled"
      (click)="undo($event, data.customConfig.data)">
      Undo</a>
    <button (click)="processClose()" class="close"><i class="fa fa-close"></i></button>
  </div>
</ng-template>