<div class="user-bar" (click)="toggleMenu()">
  <div class="user-name">
    {{ profile?.first_name | slice: 0:1 | uppercase
    }}{{ profile?.last_name | slice: 0:1 | uppercase }}
  </div>
  <div class="arrow" inlineSVG="/assets/svg/arrow-down.svg"></div>
</div>
<div
  class="s-user_menu-body"
  aria-labelledby="dropdownMenuButton"
  [@flyInOutState]="showMenu ? 'shown' : 'hidden'"
>
  <div class="mobile-header">
    <a class="logo" routerLink="/"><img src="/assets/img/logo.png" alt="" /></a>
    <a
      class="icon-close close"
      (click)="onClick()"
      [inlineSVG]="'/assets/svg/close.svg'"
    ></a>
  </div>
  <div class="mobile-links">
    <div class="s-user_menu-body-links">
      <ot-search></ot-search>
      <a href="" routerLink="/deal" (click)="onClick()">Deals</a>
      <!--      <a href="" routerLink="/contacts" (click)="onClick()">Contacts</a>-->
      <a href="" routerLink="/reports" (click)="onClick()">Reports</a>
    </div>
  </div>
  <div class="s-user_menu-body-links">
    <!-- <a class="bottom-border w-100" [href]="fultongraceUrl + '/account/clients/'" (click)="onClick()">{{
      profile?.types?.includes('agent') ? 'My Contacts' : 'Contacts'
    }}</a> -->
    <a [href]="fultongraceUrl + '/account/settings/'" (click)="onClick()"
      >My Profile</a
    >
    <!-- <a [href]="fultongraceUrl + '/account/dashboard/'" (click)="onClick()"
      >Hub</a
    > -->
    <a [href]="fultongraceUrl" (click)="onClick()">Fultongrace.com</a>
    <!-- <a
      *ngIf="profile.roles.is_listing_manager || profile.roles.is_listing_user"
      [href]="fultongraceUrl + '/account/connect/'"
      (click)="onClick()"
      >Connect</a
    > -->
    <!-- <a [href]="fultongraceUrl + '/account/'" (click)="onClick()"
      >Account Settings</a
    > -->
    <!-- <a
      [href]="
        'https://www.hub.fultongrace.com/welcome?bnc=1&rsn=noOb&fromProt=&lng='
      "
      (click)="onClick()"
      target="_blank"
      >Hub</a
    > -->
    <!-- <a
      [href]="fultongraceUrl + '/account/documents/'"
      *ngIf="profile?.roles.is_ot_agent || profile.roles?.is_ot_teamlead"
      (click)="onClick()"
      >My Documents</a
    > -->
    <!-- <a [href]="fultongraceUrl + '/account/calendar/'" (click)="onClick()"
      >Company Calendar</a
    > -->
    <!-- <a
      *ngIf="
        profile.roles.is_company_admin &&
        profile?.permissions?.includes(userPermissions.USER_MANAGEMENT)
      "
      [href]="fultongraceUrl + '/account/staff/'"
      (click)="onClick()"
      >User Management</a
    > -->
    <!-- <a [href]="fultongraceUrl + '/account/whatsnew/'" (click)="onClick()"
      >What's New</a
    > -->
    <!-- <a [href]="fultongraceUrl + '/account/mediacenter/'" (click)="onClick()"
      >Media Center</a
    > -->
    <a class="log-out" href="" (click)="logout($event)">
      <img src="/assets/icon/Log-Out.svg" alt="IconLogOut" />
      <span>Log Out</span>
    </a>
  </div>
</div>
