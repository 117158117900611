import { DocumentModel } from '@tenant/core';

export class TaskModel {
  public id: number;
  public summary: string;
  public required: boolean;
  public assignee: number;
  public due_date: string;
  public task_type: TaskType;
  public document: any;
  public progress: Progress;
  public exempt_reason: string;
  public disabled?: boolean;
  public is_created_from_default?: boolean;
  public deal?: number;

  constructor(json: TaskModel) {
    this.id = json.id;
    this.summary = json.summary;
    this.required = json.required;
    this.assignee = json.assignee;
    this.due_date = json.due_date;
    this.task_type = json.task_type;
    this.document = json.document;
    this.progress = json.progress;
    this.exempt_reason = json.exempt_reason;
    this.disabled = json.disabled;
    this.is_created_from_default = json.is_created_from_default;
    this.deal = json.deal;
  }
}

export interface TaskModel {
  id: number;
  summary: string;
  required: boolean;
  assignee: number;
  due_date: string;
  task_type: TaskType;
  document: any;
  progress: Progress;
  exempt_reason: string;
  disabled?: boolean;
  is_created_from_default?: boolean;
  deal?: number;
}

export enum Progress {
  ACTIVE = 'A',
  COMPLETE = 'C',
  EXEMPT = 'E',
}

export enum TaskType {
  TASK = 'T',
  DOCUMENT = 'D',
}
