import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@tenant/helpers';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Address } from 'libs/core/src/lib/models/address.model';
import { Profile } from '../../index';

export interface HeaderTasks {
  id: number;
  address: Address;
  active_tasks_count: number;
}

export interface HeaderTasksRes {
  tasks_by_deals: HeaderTasks[];
  total: number;
}

export interface WorkQueueData {
  my_tasks: number;
  applications: number;
  deal_approvals?: number;
  accounting?: number;
}

@Injectable()
export class HeaderDataService {
  public get workQueueData() {
    return this.workQueueState.asObservable();
  }

  private workQueueState = new BehaviorSubject({});

  constructor(private client: HttpClient) {}

  public tasks(): Observable<HeaderTasksRes> {
    return this.client.get<HeaderTasksRes>(`${ApiConfig.deal}work-queue/agent`);
  }

  public workQueue(user: Profile): Observable<WorkQueueData> {
    let url;
    if (!user) {
      return of(null);
    }
    if (user?.roles?.is_ot_admin) {
      url = `${ApiConfig?.workQueue}/admin`;
    } else if (user?.roles?.is_ot_teamlead) {
      url = `${ApiConfig.workQueue}/teamlead`;
    }
    return this.client.get<WorkQueueData>(url).pipe(
      tap((data) => {
        if (data) {
          this.workQueueState.next(data);
        }
      })
    );
  }
}
