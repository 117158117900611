<div class="dropdown" [class.show]="showDropdown">
  <div class="input-wrapper" [class.readonly]="readonly">
    <input [placeholder]="placeholder" autocomplete="off" #input class="form-control" (focus)="focus()" (blur)="blur()"
      [formControl]="filterControl" (click)="inputClick($event)" [class.is-valid]="formControlSuccess"
      [readonly]="readonly" [tabindex]="readonly ? '-1' : '0'" [class.is-invalid]="formControlDanger"
      name="select-search">
      <span inlineSVG="/assets/svg/arrow-down.svg" class="arrow-icon" [class.active]="showDropdown"></span>
  </div>
  <div class="dropdown-menu show" [@flyInOut]="showDropdown" *ngIf="showDropdown">
    <p class="no-results" *ngIf="filteredOptions?.length === 0 && !forceEmpty">No results found</p>
    <button type="button" class="dropdown-item" *ngIf="clear && (filteredOptions?.length !== 0 || forceEmpty)"
      (click)="selectItem(null)" [innerHTML]="placeholder|safeHtml">
    </button>
    <button type="button" class="dropdown-item" *ngFor="let opt of filteredOptions" (click)="selectItem(opt)"
      [innerHTML]="opt.label|safeHtml">
    </button>
    <button type="button" class="dropdown-item additional" *ngIf="additionalActionText"
      (click)="additionalClick($event)"
      [innerHTML]="additionalActionText + (filterControl.value ? ': '+filterControl.value : '')|safeHtml">
    </button>
  </div>
</div>