import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DocumentModel, DocumentProcessingService } from '@tenant/core';
import { Unsubscribe } from '@tenant/helpers';
import { debounceTime, switchMap } from 'rxjs/operators';

@Component({
  selector: 'ot-task-document-item',
  templateUrl: './task-document-item.component.html',
  styleUrls: ['./task-document-item.component.scss'],
})
export class TaskDocumentItemComponent extends Unsubscribe implements OnInit {
  @Input() public document: DocumentModel = null;
  @Output() public removeDocument = new EventEmitter<any>();
  @Output() public fileNameChanged = new EventEmitter<any>();
  filename = new FormControl('');

  constructor(private doc: DocumentProcessingService) {
    super();
  }

  public remove($event) {
    $event.preventDefault();
    this.removeDocument.emit();
  }

  public ngOnInit(): void {
    this.filename.setValue(this.document.documentName);
    this.filename.valueChanges
      .pipe(this._unsubscribeTake(), debounceTime(800))
      .subscribe((fileName) => {
        this.fileNameChanged.emit(fileName);
      });
  }

  download($event) {
    $event.preventDefault();
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([this.document.file]));
    a.setAttribute('download', this.document.file_name);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
