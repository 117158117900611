import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
  SimpleChanges,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { CommonService } from '@tenant/core';
import { Patterns } from '@tenant/helpers';
import { UniversalValidators } from 'ngx-validators';
import { Observable, of, OperatorFunction, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AddressService } from './address.service';
import { DealTypes } from 'apps/admin/src/app/deal/models/deal';
import { Address } from 'libs/core/src/lib/models/address.model';

@Component({
  selector: 'ot-address',
  templateUrl: './address.component.html',
  providers: [AddressService],
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit, OnChanges {
  @Input() public prefix;
  @Input() public errors: any = null;
  @Input() public hideCountry: any = null;
  @Input() public full = true;
  @Input() public required = true;
  @Input() public fullWidth = false;
  @Input() public empty = false;
  @Input() public group: FormGroup = new FormGroup({});
  @Input() public isAddressSearchable = false;
  @Input() public dealType: DealTypes;
  public countryOptions: any[] = [];
  public stateOptions: any[] = [];
  public showStateSelect = true;
  public form: FormGroupDirective | NgForm;
  private fieldSet: any[] = [
    {
      key: 'address_line_1',
      controlProps: [
        '',
        [
          Validators.required,
          UniversalValidators.noEmptyString,
          Validators.maxLength(50),
        ],
      ],
      controlPropsNotRequired: ['', [Validators.maxLength(50)]],
    },
    {
      key: 'address_line_2',
      controlProps: ['', [Validators.maxLength(50)]],
      controlPropsNotRequired: ['', [Validators.maxLength(50)]],
    },
    {
      key: 'city',
      controlProps: [
        '',
        [
          Validators.required,
          UniversalValidators.noEmptyString,
          Validators.minLength(3),
          Validators.maxLength(50),
        ],
      ],
      controlPropsNotRequired: [
        '',
        [Validators.minLength(3), Validators.maxLength(50)],
      ],
    },
    {
      key: 'state',
      controlProps: [
        '',
        [Validators.required, UniversalValidators.noEmptyString],
      ],
      controlPropsNotRequired: ['', []],
    },
    {
      key: 'zip_code',
      controlProps: [
        '',
        [
          Validators.required,
          UniversalValidators.noEmptyString,
          Validators.pattern(Patterns.POST_CODE),
        ],
      ],
      controlPropsNotRequired: ['', [Validators.pattern(Patterns.POST_CODE)]],
      short: true,
    },
    {
      key: 'country',
      controlProps: [
        'United States',
        [Validators.required, UniversalValidators.noEmptyString],
      ],
      controlPropsNotRequired: ['United States', []],
      short: true,
    },
  ];
  private countryChange$: Subscription;
  private prevValue: any;

  public addressLineModel = { address_line_1: '' };
  public searching = false;
  public searchFailed = false;
  public addressFormatter = (address: Address) => address.address_line_1;

  constructor(
    private common: CommonService,
    private addressService: AddressService,
    private cdr: ChangeDetectorRef,
    @Optional() public _parentForm: NgForm,
    @Optional() protected _parentFormGroup: FormGroupDirective
  ) {
    this.form = _parentForm || _parentFormGroup;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.full || changes.required) {
      this.initFieldSet();
    }
  }

  public ngOnInit() {
    this.common.countries().subscribe((v) => {
      this.countryOptions = v;
    });

    this.common.states().subscribe((v) => {
      this.stateOptions = v;
    });

    if (this.dealType) {
      this.isAddressSearchable = true;

      this.group.get('address_line_1').valueChanges.subscribe((d) => {
        this.addressLineModel = {
          address_line_1: this.group.get('address_line_1').value,
        };
        this.cdr.detectChanges();
      });
    }
    this.initFieldSet();
  }

  public addressSelected($event) {
    this.group.patchValue($event.item);
  }

  public searchAddress: OperatorFunction<string, readonly Address[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => (this.searching = true)),
      switchMap((term) =>
        this.addressService
          .search({
            search: term,
            type: this.dealType === DealTypes.RENTAL ? 'rent' : 'sell',
            limit: 99,
            offset: 0,
          })
          .pipe(
            tap(() => (this.searchFailed = false)),
            catchError(() => {
              this.searchFailed = true;
              return of([]);
            })
          )
      ),
      tap((res) => {
        this.searching = false;
      })
    );

  private initFieldSet() {
    this.fieldSet.forEach((field) => {
      if (!this.group.contains(field.key)) {
        if (this.full === false && field.short === true) {
          this.group.removeControl(field.key);
          return;
        }
        if (this.required) {
          this.group.addControl(
            field.key,
            new FormControl(
              this.empty ? '' : field.controlProps[0],
              field.controlProps[1]
            )
          );
        } else {
          this.group.addControl(
            field.key,
            new FormControl(
              this.empty ? '' : field.controlPropsNotRequired[0],
              field.controlPropsNotRequired[1]
            )
          );
        }
      }
    });
    if (this.countryChange$) {
      this.countryChange$.unsubscribe();
    }
    if (this.full !== true) {
      return;
    }
    this.showStateSelect = this.group.get('country').value === 'United States';
    this.countryChange$ = this.group
      .get('country')
      .valueChanges.subscribe((value) => {
        if (this.prevValue === value) {
          return;
        }
        this.showStateSelect = value === 'United States';
        this.prevValue = value;
      });
  }
}
