<form action="" [formGroup]="group">
  <div class="row">
    <div class="col-12">
      <ot-form-container class="city-address">
        <ot-label>
          <ng-container *ngIf="prefix">{{ prefix }}</ng-container>
          Address
        </ot-label>
        <input
          autocomplete="address-line1"
          type="text"
          otInput
          placeholder="Street Address"
          name="address_line_1"
          formControlName="address_line_1"
          [required]="required"
          *ngIf="!isAddressSearchable"
        />

        <ng-container *ngIf="isAddressSearchable">
          <input
            id="address-typeahead"
            type="text"
            class="form-control"
            [class.is-invalid]="searchFailed"
            (selectItem)="addressSelected($event)"
            [ngbTypeahead]="searchAddress"
            [inputFormatter]="addressFormatter"
            [resultTemplate]="addressTypeahead"
            [ngModel]="addressLineModel"
            [ngModelOptions]="{ standalone: true }"
            placeholder="Street Address"
          />
          <small *ngIf="searching" class="form-text text-muted"
            >searching...</small
          >
          <div class="invalid-feedback" *ngIf="searchFailed">
            Sorry, suggestions could not be loaded.
          </div>
        </ng-container>
        <ot-form-error
          *ngIf="errors?.address_line_1"
          (remove)="errors && (errors.address_line_1 = null)"
          key="custom"
        >
          {{ errors?.address_line_1[0] }}</ot-form-error
        >
      </ot-form-container>
      <ot-form-container>
        <input
          autocomplete="address-line2"
          type="text"
          otInput
          name="address_line_2"
          placeholder="Unit number"
          formControlName="address_line_2"
        />
        <ot-form-error
          *ngIf="errors?.address_line_2"
          (remove)="errors && (errors.address_line_2 = null)"
          key="custom"
        >
          {{ errors?.address_line_2[0] }}
        </ot-form-error>
      </ot-form-container>
    </div>
    <div class="col-12">
      <ot-form-container>
        <ot-label>City</ot-label>
        <input
          type="text"
          otInput
          name="city"
          formControlName="city"
          [required]="required"
          placeholder="City"
        />
        <ot-form-error
          *ngIf="errors?.city"
          (remove)="errors && (errors.city = null)"
          key="custom"
          >{{ errors?.city[0] }}
        </ot-form-error>
      </ot-form-container>
    </div>
  </div>
  <div class="row mt-md-2">
    <div class="col-md-8 col-12">
      <div *ngIf="showStateSelect">
        <ng-container *ngTemplateOutlet="stateSelect"></ng-container>
      </div>
      <ot-form-container *ngIf="!showStateSelect">
        <ot-label>State</ot-label>
        <input
          type="text"
          [required]="required"
          otInput
          name="state"
          formControlName="state"
        />
        <ot-form-error
          *ngIf="errors?.state"
          (remove)="errors && (errors.state = null)"
          key="custom"
          >{{ errors?.state[0] }}
        </ot-form-error>
      </ot-form-container>
    </div>
    <div class="col-md-4 col-12 m-md-0 pl-md-1">
      <ot-form-container>
        <ot-label>ZIP Code</ot-label>
        <input
          type="text"
          otInput
          name="zip_code"
          formControlName="zip_code"
          [required]="required"
        />
        <ot-form-error
          *ngIf="errors?.zip_code"
          (remove)="errors && (errors.zip_code = null)"
          key="custom"
        >
          {{ errors?.zip_code[0] }}
        </ot-form-error>
      </ot-form-container>
    </div>
    <div class="col-12" *ngIf="countryOptions.length > 0 && !hideCountry">
      <ot-form-container>
        <ot-label>Country</ot-label>
        <ot-select-box
          otInput
          formControlName="country"
          name="country"
          [options]="countryOptions"
          [required]="required"
        ></ot-select-box>
        <ot-form-error
          *ngIf="errors?.country"
          (remove)="errors && (errors.country = null)"
          key="custom"
        >
          {{ errors?.country[0] }}</ot-form-error
        >
      </ot-form-container>
    </div>
  </div>
  <ng-template #stateSelect>
    <ot-form-container>
      <ot-label>State</ot-label>
      <ot-select-box
        otInput
        formControlName="state"
        name="state"
        [options]="stateOptions"
        [clear]="empty"
        [required]="required"
      ></ot-select-box>
      <ot-form-error
        *ngIf="errors?.state"
        (remove)="errors && (errors.state = null)"
        key="custom"
        >{{ errors?.state[0] }}
      </ot-form-error>
    </ot-form-container>
  </ng-template>
</form>

<ng-template #addressTypeahead let-res="result">
  {{ res | address }}
</ng-template>
