export interface IDocumentModel {
  id?: number;
  date_created: string;
  file: string;
  file_content_type: string;
  file_name: string;
  file_size: number;
  status?: 'updating' | 'uploading' | 'completed';
}

export class DocumentModel implements IDocumentModel {
  public get documentName() {
    return this.file_name;
  }

  public get extension() {
    return this.file_name.split('.').reverse()[0];
  }

  public id;
  public date_created;
  public date_modified;
  public file;
  public file_content_type;
  public file_name;
  public file_size;
  public status;
  public error;

  constructor(json: IDocumentModel | any) {
    this.id = json.id;
    this.date_created = json.date_created;
    this.date_modified = json.date_modified;
    this.file = json.file;
    this.file_content_type = json.file_content_type;
    this.file_name = json.file_name;
    this.file_size = json.file_size;
  }

  public static fromArray(arr: IDocumentModel[]) {
    return arr.map((doc) => {
      return new DocumentModel(doc);
    });
  }
}
