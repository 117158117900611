import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlFileName',
})
export class UrlFileNamePipe implements PipeTransform {
  transform(url: string): string {
    let fileName;
    if (url.indexOf('?') !== -1) {
      fileName = url.slice(0, url.indexOf('?'));
    }
    return fileName.split('/').pop();
  }
}
