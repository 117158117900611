// eslint-disable  @typescript-eslint/member-ordering
type ControlSearchFn = (dynamicField: DynamicFieldDirective) => boolean;
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AutoUnsubscribe } from '@tenant/helpers';
import { Subscription } from 'rxjs';
import { DynamicFieldDirective } from './dynamic-field.directive';
import { FormConfigType } from './dynamic-form';
import { DynamicFormService } from './dynamic-form.service';
import { AbstractField } from './models/abstract-field';
import { AddressField } from './models/address-field';
import { DynamicFormConfig } from './models/dynamic-form-config';
import { GroupField } from './models/group-field';

@Component({
  selector: 'ot-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  exportAs: 'otDynamicForm',
})
@AutoUnsubscribe()
export class DynamicFormComponent implements OnInit, OnChanges {
  get controls() {
    return this._config;
  }

  get changes() {
    return this.form.valueChanges;
  }

  get valid() {
    return this.form.valid;
  }

  get value() {
    return this.form.value;
  }

  @Input('config') private _config: FormConfigType[] | DynamicFormConfig;

  get config(): FormConfigType[] {
    return this.dynamicService.config;
  }

  set config(value: FormConfigType[]) {
    this.dynamicService.initConfig(value);
  }

  @ViewChildren(DynamicFieldDirective)
  public inputs: QueryList<DynamicFieldDirective>;
  @Output() public onSubmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() public dataChange: EventEmitter<any> = new EventEmitter<any>();
  public form: FormGroup;
  @Input() public errors: any = null;
  @Input() public initialValue: any = null;
  @ViewChild('formElem', { read: ElementRef })
  public formElem: ElementRef;
  private formValueChanges$: Subscription;

  constructor(
    private fb: FormBuilder,
    private dynamicService: DynamicFormService
  ) {}

  public ngOnInit() {
    this.form = this.dynamicService.form;
    if (this.initialValue) {
      this.form.patchValue(this.initialValue);
    }
    this.formValueChanges$ = this.form.valueChanges.subscribe((v) => {
      this.dataChange.emit(v);
    });
  }

  public ngOnChanges() {
    if (this.form) {
      /*const controls = Object.keys(this.form.controls);
      const configControls = this.controls.map((item) => item.field);

      controls
        .filter((control) => configControls.indexOf(control) === -1)
        .forEach((control) => this.form.removeControl(control));

      configControls.filter((control) => controls.indexOf(control) === -1).forEach((name) => {
        const config = this.config.fields.find((control) => control.field === name);
        this.form.addControl(name, this.createControl(config));
      });*/
    }
  }

  public createGroup(controls = null) {
    if (!controls) {
      controls = this.controls;
    }
    const group = this.fb.group({});
    controls.forEach((control) =>
      group.addControl(control.field, this.createControl(control))
    );
    return group;
  }

  public createControl(config: AbstractField | GroupField) {
    if (config instanceof AddressField) {
      return this.createGroup([]);
    } else if (config instanceof GroupField) {
      return this.createGroup(config.children);
    } else {
      const { disabled, value, required } = config;
      const validation = [];
      if (required) {
        validation.push(Validators.required);
      }
      return this.fb.control({ disabled, value }, validation);
    }
  }

  public handleSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.onSubmit.emit(this.value);
  }

  public setDisabled(name: string, disable: boolean) {
    if (this.form.get(name)) {
      const method = disable ? 'disable' : 'enable';
      this.form.get(name)[method]();
      return;
    }
  }

  public setValue(name: string, value: any) {
    this.form.controls[name].setValue(value, { emitEvent: true });
  }

  public findControl(groupName, searchFn: ControlSearchFn) {
    if (groupName) {
      const group = this.inputs.find((control) => {
        return control.config.field === groupName;
      });
      if (!group) {
        return;
      }
      return group.component.instance.inputs.find(searchFn);
    } else {
      return this.inputs.find(searchFn);
    }
  }
}
