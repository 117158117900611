import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '@tenant/helpers';
import { Observable } from 'rxjs';
import { DocumentModel } from '../models';

@Injectable()
export class DocumentProcessingService {
  type = 'contact';
  itemId = null;
  constructor(private api: HttpClient) {}

  public setType(type: string, itemId: number | string) {
    this.type = type;
    this.itemId = itemId;
  }

  public upload(file: File, doc: DocumentModel = null): Observable<any> {
    const fd = new FormData();
    fd.append('name', '');
    fd.append('file', file);
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    let url = '';
    if (this.type === 'contact') {
      url = `${ApiConfig.contacts}/${this.itemId}/documents`;
    }
    return this.api.post<any>(url, fd, { headers });
  }

  public uploadDealDocument(dealId, data) {
    const fd = new FormData();
    fd.append('file_name', data.file_name);
    fd.append('file', data.file);
    if (data.task) {
      fd.append('task', data.task);
    }
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/json');
    return this.api.post<any>(`${ApiConfig.deal}${dealId}/documents`, fd, {
      headers,
    });
  }

  public updateDealDocument(id, data) {
    return this.api.patch<any>(
      `${ApiConfig.deal}${this.itemId}/documents/${id}`,
      data
    );
  }

  public updateName(id: number, newName: string): Observable<any> {
    let url = '';
    if (this.type === 'contact') {
      url = `${ApiConfig.contacts}/${this.itemId}/documents/${id}`;
    } else if (this.type === 'deal') {
      url = `${ApiConfig.deal}${this.itemId}/documents/${id}`;
    }
    return this.api.patch<any>(url, { file_name: newName });
  }

  public removeFile(id: number): Observable<any> {
    let url = '';
    if (this.type === 'contact') {
      url = `${ApiConfig.contacts}/${this.itemId}/documents/${id}`;
    } else if (this.type === 'deal') {
      url = `${ApiConfig.deal}${this.itemId}/documents/${id}`;
    }
    return this.api.delete<any>(url);
  }
}
