import { Type } from '@angular/core';
import { DynamicAddressComponent } from './dynamic-address/dynamic-address.component';
import { DynamicCheckboxComponent } from './dynamic-checkbox/dynamic-checkbox.component';
import { DynamicCommissionCurrencyComponent } from './dynamic-commission-currency/dynamic-commission-currency.component';
import { DynamicCurrencyComponent } from './dynamic-currency/dynamic-currency.component';
import { DynamicDateComponent } from './dynamic-date/dynamic-date.component';
import { DynamicDropdownComponent } from './dynamic-dropdown/dynamic-dropdown.component';
import { DynamicGrossComponent } from './dynamic-gross/dynamic-gross.component';
import { DynamicGroupComponent } from './dynamic-group/dynamic-group.component';
import { DynamicHeaderComponent } from './dynamic-header/dynamic-header.component';
import { DynamicInputComponent } from './dynamic-input/dynamic-input.component';
import { DynamicPercentComponent } from './dynamic-percent/dynamic-percent.component';
import { DynamicReferenceComponent } from './dynamic-reference/dynamic-reference.component';
import { Field } from './models/field.interface';
import { DynamicQuestionsComponent } from './dynamic-questions/dynamic-questions.component';

export function componentMapping() {
  // tslint:disable-next-line: prefer-immediate-return
  const mapping: { [type: string]: Type<Field> } = {
    text: DynamicInputComponent,
    paragraph: DynamicInputComponent,
    number: DynamicInputComponent,
    percent: DynamicPercentComponent,
    deal_address: DynamicAddressComponent,
    dropdown: DynamicDropdownComponent,
    custom_dropdown: DynamicDropdownComponent,
    group: DynamicGroupComponent,
    currency: DynamicCurrencyComponent,
    commission_currency: DynamicCommissionCurrencyComponent,
    checkbox: DynamicCheckboxComponent,
    gross: DynamicGrossComponent,
    date_range: DynamicDateComponent,
    date: DynamicDateComponent,
    reference: DynamicReferenceComponent,
    header: DynamicHeaderComponent,
    closing_questions: DynamicQuestionsComponent,
  };
  return mapping;
}
