import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbButtonsModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { BusyConfig, NgBusyModule } from 'ng-busy';
import { LaddaModule } from 'angular2-ladda';
import { TextMaskModule } from 'angular2-text-mask';
import { ClickOutsideModule } from 'ng-click-outside';
import { InlineSVGModule } from 'ng-inline-svg';
import { SelectModule } from 'ng-select';
import { ClipboardModule } from 'ngx-clipboard';
import { CookieModule } from 'ngx-cookie';
import { ExportButtonComponent } from './components/export-button/export-button.component';
import { ImagePreviewModule } from './components/image-preview/image-preview.module';
import { LoaderComponent } from './components/loader/loader.component';
import { PasswordToggleComponent } from './components/password-toggle/password-toggle.component';
import { PickPhotoComponent } from './components/pick-photo/pick-photo.component';
import { PrintButtonComponent } from './components/print-button/print-button.component';
import { ConsoleLoggerService } from './logger/console-logger.service';
import { Logger } from './logger/logger.service';
import { BusyConfigTemplateComponent } from './components/busy-config-template/busy-config-template.component';

const MODULES = [
  FormsModule,
  ReactiveFormsModule,
  InlineSVGModule,
  NgBusyModule,
  SelectModule,
  NgbButtonsModule,
  LaddaModule,
  ClipboardModule,
  ClickOutsideModule,
  ImagePreviewModule,
];

const COMPONENTS = [
  PickPhotoComponent,
  ExportButtonComponent,
  PrintButtonComponent,

  LoaderComponent,
  PasswordToggleComponent,
];

const DIRECTIVES = [];

const PIPES = [];

const PROVIDERS = [{ provide: Logger, useClass: ConsoleLoggerService }];

@NgModule({
    imports: [
        MODULES,
        CommonModule,
        RouterModule,
        TextMaskModule,
        CookieModule.forRoot(),
        NgbDropdownModule,
    ],
    declarations: [COMPONENTS, DIRECTIVES, PIPES, BusyConfigTemplateComponent],
    exports: [MODULES, COMPONENTS, DIRECTIVES, PIPES],
    providers: [{ provide: BusyConfig, useFactory: busyConf }]
})
export class OtModule {
  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: OtModule,
      providers: [...PROVIDERS],
    };
  }
}

export function busyConf() {
  return new BusyConfig({
    backdrop: true,
    delay: 100,
    minDuration: 400,
    template: BusyConfigTemplateComponent,
  });
}
