import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  ViewChild,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  NgbActiveModal,
  NgbDatepickerConfig,
} from '@ng-bootstrap/ng-bootstrap';

import {
  DocumentModel,
  DocumentProcessingService,
  Profile,
  ProfileService,
} from '@tenant/core';
import { NgbDatepickerConfigFactory } from '@tenant/forms';
import { AutoUnsubscribe } from '@tenant/helpers';
import { Deal, AgentTypes } from 'apps/admin/src/app/deal/models/deal';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map, share, startWith, takeWhile, tap } from 'rxjs/operators';
import { TaskModel, TaskType } from '../taskModel';
import { TasksService } from '../tasks.service';
import * as moment from 'moment-mini-ts';

@Component({
  selector: 'ot-task-modal',
  templateUrl: './task-modal.component.html',
  styleUrls: ['./task-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NgbDatepickerConfig,
      useFactory: NgbDatepickerConfigFactory,
    },
  ],
})
@AutoUnsubscribe()
export class TaskModalComponent implements OnInit, OnDestroy {
  private _isCreate = false;

  get isCreate(): boolean {
    return this._isCreate;
  }

  set isCreate(value: boolean) {
    this._isCreate = value;
    this.isCreate$.next(value);
  }

  private _deals = [];

  @Input()
  get deals(): any[] {
    return this._deals;
  }

  set deals(value: any[]) {
    this._deals = value;
  }

  @Input() public prePopulateAgent = null;
  public fileChanged: boolean;
  public fg: FormGroup;
  public type = new FormControl('T');
  public document: DocumentModel = null;
  public agents = [];
  @Input() public task: TaskModel = null;
  public errors: any;
  public userRole$: Observable<any>;
  public user: Profile;
  public canCreateRequired$: Observable<any>;
  public editable$: Observable<boolean>;
  public isCreate$ = new BehaviorSubject(false);
  public loading = false;
  private alive = true;
  private typeChanges$: Subscription;
  private dealChanges$: Subscription;
  private dateChanges$: Subscription;

  constructor(
    public active: NgbActiveModal,
    private doc: DocumentProcessingService,
    private taskService: TasksService,
    private cd: ChangeDetectorRef,
    private profile: ProfileService
  ) {
    this.fg = this.initForm();
    this.userRole$ = this.profile.state.pipe(
      takeWhile(() => this.alive),
      filter((v) => !!v),
      map((v: any) => v.roles),
      startWith([null])
    );
    this.editable$ = combineLatest([this.userRole$, this.isCreate$]).pipe(
      map((v) => {
        const role = v[0];
        const isCreate = v[1];
        return (
          isCreate ||
          !(!isCreate && this.fg.get('required').value && role.is_ot_agent)
        );
      }),
      tap(() => {
        this.cd.detectChanges();
      }),
      share()
    );
    this.canCreateRequired$ = this.userRole$.pipe(
      map((v) => {
        return v.is_ot_admin || v.is_ot_teamlead;
      })
    );
  }

  public exempt($event) {
    if (!this.task) {
      return;
    }
    this.active.dismiss({ exempt: true });
  }

  public ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.loading = false;
    this.dealChanges$ = this.fg.get('deal_id').valueChanges.subscribe((val) => {
      if (val) {
        this.loadAgents(val);
      }
    });
    if (this.task) {
      this.typeChange(this.task.task_type);
      this.type.setValue(this.task.task_type);
      this.document = this.task.document;

      this.fg.get('deal_id').patchValue(this.task.deal);
      this.fg.get('summary').patchValue(this.task.summary);
      this.fg.get('assignee').patchValue(this.task.assignee);
      this.fg.get('task_type').patchValue(this.task.task_type);
      this.fg.get('required').patchValue(this.task.required);
      if (this.task.due_date) {
        this.fg
          .get('due_date')
          .patchValue(moment(this.task.due_date).format('MM/DD/YYYY'));
        this.fg
          .get('time')
          .patchValue(moment(this.task.due_date).format('HH:mm'));
      }
      this.loadAgents(this.task.deal);
    } else {
      if (this.deals && this.deals.length === 1) {
        this.fg.get('deal_id').patchValue(this.deals[0].value);
      }
    }
    this.typeChanges$ = this.type.valueChanges.subscribe((val) => {
      this.typeChange(val);
    });
    this.dateChanges$ = this.fg
      .get('due_date')
      .valueChanges.subscribe((val) => {
        if (val === '') {
          this.fg.get('due_date').setValue(null);
        }
      });
  }

  public changeFile($event) {
    const file: File = $event[0];
    const ext = file.name.split('.').reverse()[0];
    if (
      ext &&
      ['jpg', 'jpeg', 'png', 'pdf'].indexOf(ext?.toLowerCase()) === -1
    ) {
      this.errors = Object.assign({}, this.errors, {
        file: [
          `File has not allowed extension "${ext}", allowed types: jpg, jpeg, png, pdf`,
        ],
      });
      return;
    } else {
      this.errors = Object.assign({}, this.errors, { file: null });
    }

    this.fg.get('document').get('file').setValue(file);
    this.document = new DocumentModel({
      file_name: file.name,
      status: 'completed',
      size: file.size,
      name: file.name,
      file: file,
    });

    const nameField = this.fg.get('document').get('file_name');
    nameField.setValue(nameField.value || this.document.file_name);
    this.fileChanged = true;
  }

  fileNameChanged(fileName) {
    this.fg.get('document').get('file_name').setValue(fileName);
    this.fileChanged = true;
  }

  public removeHandler($event) {
    this.document = null;
    this.fg.get('document').reset();
    this.cd.markForCheck();
  }

  public addTask($event) {
    $event.preventDefault();
    if (this.fg.invalid) {
      return;
    }
    this.loading = true;
    if (!this.fileChanged && this.fg.get('document')) {
      this.fg.get('document').disable();
    }

    if (this.task?.is_created_from_default) {
      this.fg.get('summary').disable();
      this.fg.get('task_type').disable();
      this.fg.get('required').disable();
    }
    if (this.fg.get('due_date')?.value === '') {
      this.fg.get('due_date').setValue(null);
    } else if (this.fg.get('due_date')?.value) {
      const dueDate = moment(
        `${this.fg.get('due_date').value} ${this.fg.get('time').value}`,
        'MM/DD/YYYY hh:mm'
      ).toISOString();
      this.fg.get('due_date').setValue(dueDate);
    }

    if (this.task) {
      this.fg.get('task_type').disable();
      if (this.user?.roles?.is_ot_agent) {
        this.fg.get('assignee').disable();
      }
      this.taskService
        .updateTask(this.task.id, {
          ...this.fg.value,
        })
        .subscribe(
          (res) => {
            this.active.close(res);
          },
          (err) => {
            console.log(err);
            try {
              this.errors = err.error;
            } catch (e) {
              console.warn(e);
            }
          }
        );
    } else {
      this.taskService
        .addTask(this.fg.get('deal_id').value, {
          ...this.fg.value,
          ...{ task_type: this.type.value },
        })
        .subscribe(
          (res) => {
            this.active.close(res);
            this.loading = false;
          },
          (err) => {
            try {
              this.errors = err.error;
              this.loading = false;
            } catch (e) {
              console.warn(e);
            }
          }
        );
    }
  }

  public ngOnDestroy(): void {
    this.alive = false;
  }

  private loadAgents(dealId) {
    this.taskService.getDealById(dealId).subscribe((res: Deal) => {
      this.agents = res.agents.map((v) => ({
        value: v.agent.id,
        label: `${v.agent?.first_name} ${v.agent?.last_name}`,
      }));
      this.cd.detectChanges();
      const userId = this.fg.get('assignee');
      const primaryAgent = res.agents.find(
        (agent) => agent.agent_type === AgentTypes.PRIMARY
      );
      if (
        this.prePopulateAgent &&
        res.agents.find((v) => v.agent.id === this.prePopulateAgent)
      ) {
        userId.patchValue(this.prePopulateAgent);
      } else if (
        primaryAgent &&
        !res.agents.find((v) => v.agent.id === userId.value)
      ) {
        userId.patchValue(primaryAgent.agent.id);
      }
      this.cd.detectChanges();
    });
  }

  private typeChange(val) {
    const oldVal = this.fg.value;
    if (val === TaskType.TASK) {
      this.document = null;
      this.fg = this.initForm();
    } else {
      this.fg.reset();
      this.fg.addControl(
        'document',
        new FormGroup({
          file_name: new FormControl(null),
          file: new FormControl(null),
        })
      );
    }
    this.fg.patchValue(oldVal);
  }

  private initForm() {
    return new FormGroup({
      summary: new FormControl(null, [
        Validators.required,
        Validators.maxLength(50),
      ]),
      due_date: new FormControl(null),
      task_type: new FormControl(null),
      deal_id: new FormControl(null, [Validators.required]),
      required: new FormControl(false, []),
      assignee: new FormControl(null, [Validators.required]),
      time: new FormControl(null),
    });
  }
}
