import { Component, OnDestroy, OnInit } from '@angular/core';
import { SafeResourceUrl, Title } from '@angular/platform-browser';
import { Observable, ReplaySubject } from 'rxjs';
import { DashboardService } from './dashboard.service';

@Component({
  selector: 'ot-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public tasksLoading = true;
  public summary: any;
  public dealType = 'all';
  public tasks: any[] = [];
  public role$: Observable<any>;
  public calendarUrl: SafeResourceUrl;
  private destroy$ = new ReplaySubject(1);

  constructor(private dash: DashboardService, private title: Title) {
    title.setTitle(`Dashboard - OfficeTroop`);
  }

  public changeDealType(type) {
    this.dealType = type;
    this.reloadSummary();
  }

  ngOnInit() {
    this.reloadTasks();
    this.reloadSummary();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public reloadTasks() {
    this.dash.tasks().subscribe(
      (data: any[]) => {
        this.tasks = data;
        this.tasksLoading = false;
      },
      () => {
        this.tasksLoading = false;
      }
    );
  }

  private reloadSummary() {
    this.dash.summary(this.dealType).subscribe((res) => {
      this.summary = res;
    });
  }
}
