import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { AbstractField } from './abstract-field';

export class CommissionCurrencyField extends AbstractField {
  public control(): AbstractControl {
    const validators = this.getValidators();
    this.validation = validators;
    const defType = this.meta && this.meta.default_type;
    return new FormGroup({
      value: new FormControl(null, validators),
      type: new FormControl(defType ? defType : '$', [Validators.required])
    });
  }
}
