import { environment } from 'apps/admin/src/environments/environment';
import { MenuBar } from './menu-bar.model';

const fultongraceUrl = environment.FULTONGRACE_URL;

export const MenuBarDefiniton: MenuBar[] = [
  {
    id: 0,
    name: 'Fultongrace',
    icon: '/assets/svg/menu.svg',
    target: '_self',
  },
  {
    id: 1,
    name: 'Dashboard',
    icon: '/assets/svg/window.svg',
    href: fultongraceUrl + '/account/dashboard/',
    target: '_self',
  },
  {
    id: 2,
    name: 'Contacts',
    icon: '/assets/svg/contacts.svg',
    href: fultongraceUrl + '/account/clients/',
    target: '_self',
  },
  {
    id: 3,
    name: 'Deal Management',
    icon: '/assets/svg/usd-icon.svg',
    target: '_self',
    dropdown: {
      types: [
        {
          name: 'Deals',
          link: '/deal',
          target: '_self',
        },
        {
          name: 'Reports',
          link: '/reports/leaderboard',
          target: '_self',
        },
      ],
      isOpen: false,
    },
  },
  {
    id: 4,
    name: 'Property search',
    icon: '/assets/svg/property-search.svg',
    target: '_self',
    dropdown: {
      types: [
        {
          name: 'Sales',
          href: fultongraceUrl + '/account/search/sell',
          target: '_self',
        },
        {
          name: 'Rentals',
          href: fultongraceUrl + '/account/search/rent',
          target: '_self',
        },
      ],
      isOpen: false,
    },
  },
  {
    id: 5,
    name: 'Marketing Center',
    icon: '/assets/svg/default-image.svg',
    target: '_self',
    dropdown: {
      types: [
        {
          name: 'Agent Marketing',
          href: 'https://resources.fultongrace.com/agent-team-marketing',
          target: '_blank',
        },
        {
          name: 'Property Marketing',
          href: 'https://resources.fultongrace.com/property-marketing',
          target: '_blank',
        },
        {
          name: 'Digital Media',
          href: fultongraceUrl + '/account/mediacenter/standard',
          target: '_self',
        },
      ],
      isOpen: false,
    },
  },
  {
    id: 6,
    name: 'Resource Center',
    icon: '/assets/svg/resource-center.svg',
    target: '_self',
    dropdown: {
      types: [
        {
          name: 'Education & Training',
          href: 'https://resources.fultongrace.com/education-and-training',
          target: '_blank',
        },
        {
          name: 'Documents & Forms',
          href: 'https://resources.fultongrace.com/forms-and-documents',
          target: '_blank',
        },
        {
          name: 'Help Center',
          href: 'https://resources.fultongrace.com/helpcenter',
          target: '_blank',
        },
      ],
      isOpen: false,
    },
  },
  // {
  //   id: 7,
  //   name: 'Connect',
  //   icon: '/assets/svg/connect.svg',
  //   href: fultongraceUrl + '/account/connect',
  //   target: '_self',
  // },
  // {
  //   id: 8,
  //   name: 'Hub',
  //   icon: '/assets/svg/diagram.svg',
  //   href: 'https://www.hub.fultongrace.com/welcome?bnc=1&rsn=noOb&fromProt=&lng=',
  //   target: '_blank',
  // },
  {
    id: 8,
    name: 'User Management',
    icon: '/assets/svg/user-management.svg',
    href: fultongraceUrl + '/account/staff/',
    target: '_self',
  },
  {
    id: 9,
    name: "What's new",
    icon: '/assets/svg/notification.svg',
    href: fultongraceUrl + '/account/whatsnew/',
    target: '_self',
  },
  {
    id: 10,
    name: 'Events Calendar',
    icon: '/assets/svg/menu-calendar.svg',
    href: fultongraceUrl + '/account/calendar/',
    target: '_self',
  },
  {
    id: 11,
    name: 'Follow Up Boss',
    icon: '/assets/svg/follow-up-boss.svg',
    href: 'https://www.followupboss.com',
    target: '_blank',
  },
];
